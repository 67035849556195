<template>
 <PageHeading
  :title="$t('ctiDetail')"
  :button-title="$t('newDestination')"
  :has-action-button="true"
  :has-meta-data="false"
  :first-button-title="
   userPermissions.permissions.includes('smart-routings.*') ||
   userPermissions.permissions.includes('smart-routings.edit')
    ? $t('importFile')
    : undefined
  "
  :second-button-title="
   userPermissions.permissions.includes('smart-routings.*') ||
   userPermissions.permissions.includes('smart-routings.edit')
    ? $t('export')
    : undefined
  "
  :has-permission="
   userPermissions.permissions.includes('smart-routings.*') ||
   userPermissions.permissions.includes('smart-routings.create')
  "
  @open="(this.openForm = !this.openForm), (this.openUpdateForm = false)"
  @import="isPopoverOpen = !isPopoverOpen"
  @export="exportDestinations(ctiData.id)"
  @update:search="$emit('update:search', $event)"
 />
 <div
  v-show="isPopoverOpen"
  class="max-w-7xl mx-auto bg-white rounded-lg shadow-lg ring-1 ring-black ring-opacity-5"
 >
  <div class="p-4 w-full">
   <div
    class="flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md hover:border-gray-500"
    @drop="dropHandler($event)"
    @dragover="dragOverHandler($event)"
   >
    <div class="space-y-1 text-center">
     <svg
      xmlns="http://www.w3.org/2000/svg"
      class="mx-auto h-12 w-12 text-gray-400"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      stroke-width="1"
     >
      <path
       stroke-linecap="round"
       stroke-linejoin="round"
       d="M9 13h6m-3-3v6m5 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
      />
     </svg>
     <div class="flex justify-center text-sm text-gray-600">
      <label
       for="file-upload"
       class="relative cursor-pointer rounded-md font-medium text-main-color"
      >
       <span> {{ $t("uploadAFile") }}</span>
       <input
        id="file-upload"
        name="file-upload"
        type="file"
        ref="file"
        class="sr-only"
        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
        @change="handleFileUpload($event)"
       />
      </label>
      <p class="pl-1">{{ $t("or") }} {{ $t("dragAndDrop") }}</p>
     </div>

     <div class="flex justify-center">
      <ExclamationCircleIcon
       v-show="errorFormMandateUpload"
       class="h-4 w-4 text-red-500 mr-1"
       aria-hidden="true"
      />
      <p :class="errorFormMandateUpload ? 'text-xs text-red-600' : 'text-xs text-gray-500'">
       {{ $t("onlyFileAccepterd", { file: "excel" }) }}
      </p>
     </div>
     <p v-show="this.file.name" class="text-sm text-gray-700">
      <font-awesome-icon icon="file-import" class="h-4 w-4 text-gray-500" aria-hidden="true" />
      {{ this.file.name }}
     </p>
    </div>
   </div>
  </div>
  <button
   v-show="this.file"
   @click="submitFile('Please insert file')"
   type="button"
   class="max-w-7xl mx-auto uppercase rounded group body__header--button sm:text-sm lg:text-sm xl:text-sm md:text-sm text-white flex justify-end items-center text-sm font-medium shadow p-2"
  >
   {{ $t("upload") }}
  </button>
  <div class="rounded-md bg-blue-50 p-4 m-4">
   <div class="flex">
    <div class="flex-shrink-0">
     <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      fill="currentColor"
      class="h-5 w-5 text-blue-400"
     >
      <path
       fill-rule="evenodd"
       d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a.75.75 0 000 1.5h.253a.25.25 0 01.244.304l-.459 2.066A1.75 1.75 0 0010.747 15H11a.75.75 0 000-1.5h-.253a.25.25 0 01-.244-.304l.459-2.066A1.75 1.75 0 009.253 9H9z"
       clip-rule="evenodd"
      />
     </svg>
    </div>
    <div class="ml-3 flex-1 md:flex md:justify-between">
     <p class="text-sm text-blue-700">
      {{ $t("importContactMsg") }}
     </p>
    </div>
   </div>
  </div>
 </div>

 <div v-if="openForm || openUpdateForm" class="flex flex-col">
  <div class="-my-0 overflow-x-auto sm:-mx-6 lg:-mx-0">
   <div class="py-5 align-middle inline-block min-w-full sm:px-6 lg:px-10">
    <NewDestinationForm
     v-if="this.openForm"
     @closeForm="this.openForm = false"
     :id="this.id"
     @activeBanner="$emit('activeBanner'), getCti(), (openForm = false)"
     @successMsg="$emit('successMsg', $event)"
     @success="$emit('success', $event)"
    />
    <UpdateDestinationForm
     v-if="openUpdateForm"
     @closeForm="this.openUpdateForm = false"
     :name="this.destName"
     :id="this.destId"
     :origin="this.destOrigin"
     @activeBanner="$emit('activeBanner'), getCti(), (openUpdateForm = false)"
     @successMsg="$emit('successMsg', $event)"
     @success="$emit('success', $event)"
    />
   </div>
  </div>
 </div>

 <div>
  <div class="flex flex-col">
   <div class="-my-0 sm:-mx-6 lg:-mx-0">
    <div
     v-if="tbody?.length > 0 && !this.preview"
     class="align-middle inline-block min-w-full sm:px-6 lg:px-10"
    >
     <div class="sm:flex sm:items-center sm:px-2">
      <div class="sm:flex-auto">
       <h1 class="text-lg font-semibold leading-6 text-gray-900">
        {{ ctiData.name }}
       </h1>
       <button
        :title="$t('copyToClipboard')"
        @click="copyClipboard()"
        class="mt-2 text-xs text-gray-700 hover:cursor-pointer hover:text-gray-900"
       >
        <span id="tokenValue">
         {{ $t("ctiToken", { apiUrl: this.$cookie.getCookie("API"), token: ctiData.token }) }}
        </span>
       </button>
      </div>
     </div>

     <CTIDestinationsTable
      v-if="!this.preview"
      :preview="preview"
      :thead="thead"
      :tbody="filterDestination()"
      :id="this.id"
      :data="[]"
      @openEditForm="(...args) => this.openEditForm(...args)"
      @deleteDestination="(...args) => deleteDestination(...args)"
      @sort="(this.currentSort = $event), sort($event)"
      @action="getData($event)"
      @action-detail="goToDetail($event)"
     />
    </div>
    <div v-else-if="this.preview" class="align-middle inline-block min-w-full sm:px-6 lg:px-10">
     <CTIDestinationsTable
      :thead="theadPreview"
      :tbody="previewData.elements"
      :data="[]"
      :preview="preview"
      :previewData="previewData"
      :id="this.id"
      @action="getData($event)"
      @sort="(this.currentSort = $event), sort($event)"
      @activeBanner="$emit('activeBanner')"
      @successMsg="$emit('successMsg', $event)"
      @success="$emit('success', $event)"
     />
    </div>
    <div v-else class="justify-center pt-20">
     <EmptyState
      :title="$t('emptyStateTitle', { title: $t('destination') })"
      :subtitle="$t('emptyStateSubtitle', { title: $t('destination') })"
     />
    </div>
   </div>
  </div>
 </div>

 <AskConfirmationDialog ref="askConfirmationDialog" />
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import { SearchIcon } from "@heroicons/vue/solid";
import PageHeading from "../components/PageHeading.vue";

import TablePagination from "../components/TablePagination.vue";
import CTIDetailHeader from "../components/CTIDetailHeader.vue";
import NewDestinationForm from "../components/NewDestinationForm.vue";
import UpdateDestinationForm from "../components/UpdateDestinationForm.vue";
import SuccessBanner from "../components/SuccessBanner.vue";
import CTIDestinationsTable from "../components/CTIDestinationsTable.vue";
import EmptyState from "../components/EmptyState.vue";
import AskConfirmationDialog from "../components/AskConfirmationDialog.vue";

const userPermissions = JSON.parse(localStorage.getItem("userPermissions"));

export default {
 name: "CTIDetail",
 props: ["account", "id", "search"],
 components: {
  AskConfirmationDialog,
  PageHeading,
  SearchIcon,
  TablePagination,
  CTIDetailHeader,
  NewDestinationForm,
  UpdateDestinationForm,
  SuccessBanner,
  CTIDestinationsTable,
  EmptyState,
 },
 data() {
  return {
   preview: false,
   isPopoverOpen: false,
   file: "",
   previewData: [],
   ctiData: {},
   ctiDestinations: [],
   searchOrigin: "",
   searchDestination: "",
   previewTableHeader: ["ORIGIN", "DESTINATION", "UPDATED DATE"],
   openForm: false,
   openUpdateForm: false,
   destName: "",
   destId: "",
   destOrigin: "",
   successMsg: "",
   activeBanner: false,
   isSuccess: true,
   key: false,
   apiUrl: undefined,
   currentSort: "id",
   currentSortDir: "desc",
   thead: [
    { name: "origin", sort: "origin", isSort: false },
    { name: "destination" /*, sort: "lastName"*/, isSort: false },
    { name: "created_at", sort: "created_at", isSort: false },
    { name: "updated_at", sort: "updated_at", isSort: false },
   ],
   theadPreview: [
    { name: "origin", sort: "origin", isSort: false },
    { name: "destination" /*, sort: "lastName"*/, isSort: false },
   ],
   tbody: [],
   userPermissions,
  };
 },
 methods: {
  openEditForm(...args) {
   this.openUpdateForm = true;
   this.openForm = false;
   this.destName = args[1];
   this.destOrigin = args[2];
   this.destId = args[0];
   window.scrollTo(0, 100);
  },
  setPreviewData(event) {
   this.previewData = event;
   this.preview = true;
   this.isPopoverOpen = false;
  },
  async getCti() {
   this.$emit("setLoading", true);
   try {
    const res = await axios.get(
     `${this.$cookie.getCookie("API")}/api/v1/ctis/${this.id}?customerAccount=${this.account}`,
     {
      headers: {
       Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
     }
    );
    if (res) {
     this.ctiData = res.data;
     this.ctiDestinations = res.data.cti_destinations;

     this.tbody = res.data.cti_destinations;
     this.$emit("setLoading", false);
    }
   } catch (error) {
    this.errorHandling(error);
    this.$router.push("/cti");
   }
  },
  sort(s) {
   if (s === this.currentSort) {
    this.currentSortDir = this.currentSortDir === "asc" ? "desc" : "asc";
   }
   this.currentSort = s;
  },
  sortedDatas() {
   return this.tbody.sort((a, b) => {
    let toBeSorted = this.currentSort;
    let modifier = 1;
    if (this.currentSortDir === "desc") modifier = -1;
    if (toBeSorted === "created_at" || toBeSorted === "updated_at") {
     if (new Date(a[toBeSorted]) < new Date(b[toBeSorted])) return -1 * modifier;
     if (new Date(a[toBeSorted]) > new Date(b[toBeSorted])) return 1 * modifier;
    } else if (toBeSorted === "origin") {
     if (parseInt(a[toBeSorted]) < parseInt(b[toBeSorted])) return -1 * modifier;
     if (parseInt(a[toBeSorted]) > parseInt(b[toBeSorted])) return 1 * modifier;
    } else {
     if (a[toBeSorted] < b[toBeSorted]) return -1 * modifier;
     if (a[toBeSorted] > b[toBeSorted]) return 1 * modifier;
    }
    return 0;
   });
  },
  filterDestination() {
   return this.sortedDatas().filter((res) => {
    if (this.search) {
     const searchLower = this.search.toLowerCase();
     const originMatch = res.origin.toLowerCase().includes(searchLower);
     const destinationMatch = res.destination.toLowerCase().includes(searchLower);
     const createdAtMatch = this.$d(res.created_at, "shortText")
      .toLowerCase()
      .includes(searchLower.toLowerCase());
     const updatedAtMatch = this.$d(res.updated_at, "longText")
      .toLowerCase()
      .includes(searchLower.toLowerCase());

     return originMatch || destinationMatch || createdAtMatch || updatedAtMatch;
    }
    return res;
   });
  },
  async exportDestinations() {
   this.$emit("setLoading", true);

   const res = await axios
    .get(
     `${this.$cookie.getCookie("API")}/api/v1/ctiDestinations/export?customerAccount=${
      this.account
     }&cti_id=${this.id}`,
     {
      headers: {
       "Content-Type": "application/json",
       Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      responseType: "blob",
     }
    )
    .catch((err) => {
     this.$emit("setLoading", false);

     console.error(err);
    });
   const file = new Blob([await res.data], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
   });
   const fileURL = URL.createObjectURL(file);
   this.isLoading = false;
   window.open(fileURL);
  },
  async deleteDestination(...args) {
   let id = args[0];
   let name = args[1];
   let title = this.$t("deleteDestination", { name: name });
   let confirmationMessage = this.$t("deleteDestinationMessage");
   let confirmButton = this.$t("delete");
   let goBack = this.$t("cancel");
   let successMsg = this.$t("elementDeletedSuccessfully", { element: this.$t("destination") });
   const r = await this.$refs.askConfirmationDialog.show({
    title: title,
    message: confirmationMessage,
    okButton: confirmButton,
    cancelButton: goBack,
    action: "delete",
   });
   if (r) {
    axios
     .delete(
      `${this.$cookie.getCookie("API")}/api/v1/ctiDestinations/${id}?customerAccount=${
       this.account
      }`,
      {
       headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
       },
      }
     )
     .then((res) => {
      if (res) {
       this.$emit("activeBanner");
       this.$emit("success", true);
       this.$emit("successMsg", successMsg);
       this.getCti();
      }
     })
     .catch(function (error) {
      this.errorHandling(error);
      this.$emit("activeBanner");
      this.$emit("success", false);
      this.$emit("successMsg", this.errorDataResponse);
     });
   }
  },
  dropHandler(ev) {
   console.log("File(s) dropped");

   // Prevent default behavior (Prevent file from being opened)
   ev.preventDefault();

   if (ev.dataTransfer.items) {
    // Use DataTransferItemList interface to access the file(s)
    for (var i = 0; i < ev.dataTransfer.items.length; i++) {
     // If dropped items aren't files, reject them
     const type = ev.dataTransfer.items[i].type;

     if (
      type === "multipart/form-data" ||
      type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
     ) {
      var file = ev.dataTransfer.items[i].getAsFile();
      this.file = file;
      this.errorFormMandateUpload = false;
     } else {
      this.errorFormMandateUpload = true;
     }
    }
   } else {
    // Use DataTransfer interface to access the file(s)
    for (var i = 0; i < ev.dataTransfer.files.length; i++) {
     console.log("... file[" + i + "].name = " + ev.dataTransfer.files[i].name);
    }
   }
  },
  dragOverHandler(ev) {
   // Prevent default behavior (Prevent file from being opened)
   ev.preventDefault();
  },
  handleFileUpload() {
   let type = this.$refs.file.files[0].type;
   if (
    type === "multipart/form-data" ||
    type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
   ) {
    this.file = this.$refs.file.files[0];
    this.errorFormMandateUpload = false;
   } else {
    this.errorFormMandateUpload = true;
   }
  },
  submitFile(noFileMsg) {
   /* Initialize the form data */
   let formData = new FormData();
   formData.append("file", this.file);
   /* Add the form data we need to submit */
   if (this.file) {
    this.$emit("setLoading", true);

    axios
     .post(
      `${this.$cookie.getCookie("API")}/api/v1/ctiDestinations/upload?customerAccount=${
       this.account
      }&cti_id=${this.id}`,
      formData,
      {
       headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "content-type": "multipart/form-data; boundary=---011000010111000001101001",
       },
      }
     )
     .then((res) => {
      this.$emit("setLoading", false);

      try {
       this.setPreviewData(res.data);
      } catch (error) {
       this.errorHandling(error);
      }
     })
     .catch((res) => {
      this.isLoading = false;
     });
   } else {
    this.sendMessage(noFileMsg, false);
   }

   /* Make the request to the POST /single-file URL  */
  },
  copyClipboard() {
   let token = document.getElementById("tokenValue").innerHTML;
   try {
    navigator.clipboard.writeText(token);
    let msg = this.$t("contentCopied");
    this.$emit("activeBanner");
    this.$emit("success", true);
    this.$emit("successMsg", msg);
   } catch (err) {
    console.error("Failed to copy: ", err);
   }
  },
 },
 mounted() {
  this.getCti();
 },
};
</script>

<style>
.card_info {
 display: flex;
 flex-direction: column;
 justify-content: center;
 align-items: center;
 border-radius: 10px;
}
.card_info_data {
 box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.19);
 max-width: fit-content;
 border-radius: 10px;
 margin: 90px 20px 0px 20px;
 overflow-x: auto;
}
.cti_card_padding {
 padding: 30px;
}
.form_add_new_cti_padding {
 padding: 5px 40px 10px 40px;
}
</style>
