<template>
  <div class="z-10 sticky top-0" :key="this.closeForm">
    <div
      :class="
        `z-10 body__header shadow flex flex-wrap items-center justify-between px-8 py-2 border-t`
      "
    >
      <div class="">
        <button
          :class="[
            open ? `text-white` : `text-white`,
            `rounded group body__header--button sm:text-sm lg:text-sm xl:text-sm md:text-sm text-white inline-flex items-center text-sm font-medium shadow p-2 mr-0 uppercase`,
          ]"
          @click="$emit('openForm')"
        >
          <span>{{ $t("addDestination") }}</span>
          <!-- <ChevronDownIcon :class="[`ml-2 h-5 w-5`]" aria-hidden="true" /> -->
        </button>
      </div>
      <!-- <div class="col-end-3">
        <div class="pt-2 pb-2">
          <button
            @click.prevent="
              deleteAllDestinations(
                this.id,
                'Delete current CTI',
                'Are you sure you want to delete all destinations?',
                'Confirm',
                'Go back',
                'Destinations deleted successfully'
              )
            "
            type="button"
            class="rounded inline-flex ml-10 items-center px-4 py-2 border border-transparent text-sm font-medium shadow-sm text-white bg-red-500 hover:bg-red-700 focus:outline-none"
          >
            Delete Destinations
          </button>
        </div>
      </div> -->
      <div class="text-left flex gap-5">
        <Popover class="relative" v-slot="{ open }">
          <PopoverButton
            :class="[
              open ? 'text-gray-900' : 'text-gray-500',
              'group bg-white rounded-md inline-flex items-center text-base font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 capitalize',
            ]"
          >
            <span>{{ $t("importFile") }}</span>
            <ChevronDownIcon
              :class="[
                open ? 'text-gray-600' : 'text-gray-400',
                'ml-2 h-5 w-5 group-hover:text-gray-500',
              ]"
              aria-hidden="true"
            />
          </PopoverButton>

          <transition
            enter-active-class="transition ease-out duration-200"
            enter-from-class="opacity-0 translate-y-1"
            enter-to-class="opacity-100 translate-y-0"
            leave-active-class="transition ease-in duration-150"
            leave-from-class="opacity-100 translate-y-0"
            leave-to-class="opacity-0 translate-y-1"
          >
            <PopoverPanel
              class="absolute z-10 transform right-0 mt-3 px-2 max-w-md sm:px-0"
            >
              <div
                class="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden"
              >
                <div
                  class="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8"
                >
                  <a
                    class="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50 transition ease-in-out duration-150"
                  >
                    <div class="">
                      <!-- <div class="inline-flex ml-0 pb-1 pt-1 item-center">
                        <input
                          class="rounded text-center text-white pt-3 ml-0 w-auto items-center px-0 py-0 border border-transparent text-xs font-medium focus:outline-none"
                          type="file"
                          id="file"
                          ref="file"
                          v-on:change="handleFileUpload()"
                        />
                        <button
                          @click="submitFile('Please insert file')"
                          type="button"
                          :class="
                            `mt-1.5 uppercase rounded group body__header--button sm:text-sm lg:text-sm xl:text-sm md:text-sm text-white inline-flex items-center text-sm font-medium shadow p-2 encom_pink_bg:hover mr-0`
                          "
                        >
                          {{ $t("upload") }}
                        </button>
                      </div> -->
                      <div
                        class="w-80 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md hover:border-gray-500"
                        @drop="dropHandler($event)"
                        @dragover="dragOverHandler($event)"
                      >
                        <div class="space-y-1 text-center">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="mx-auto h-12 w-12 text-gray-400"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            stroke-width="1"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M9 13h6m-3-3v6m5 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
                            />
                          </svg>
                          <div
                            class="flex justify-center text-sm text-gray-600"
                          >
                            <label
                              for="file-upload"
                              class="relative cursor-pointer rounded-md font-medium text-main-color"
                            >
                              <span> {{ $t("uploadAFile") }}</span>
                              <input
                                id="file-upload"
                                name="file-upload"
                                type="file"
                                ref="file"
                                class="sr-only"
                                accept="image/png, image/jpeg, image/jpg, application/pdf"
                                @change="handleFileUpload($event)"
                              />
                            </label>
                            <p class="pl-1">
                              {{ $t("or") }} {{ $t("dragAndDrop") }}
                            </p>
                          </div>
                          <div class="flex justify-center">
                            <ExclamationCircleIcon
                              v-show="errorFormMandateUpload"
                              class="h-4 w-4 text-red-500 mr-1"
                              aria-hidden="true"
                            />
                            <p
                              :class="
                                errorFormMandateUpload
                                  ? 'text-xs text-red-600'
                                  : 'text-xs text-gray-500'
                              "
                            >
                              Only Excel files {{ $t("accepted") }}.
                            </p>
                          </div>
                          <p
                            v-show="this.file.name"
                            class="text-sm text-gray-700"
                          >
                            <font-awesome-icon
                              icon="file-import"
                              class="h-4 w-4 text-gray-500"
                              aria-hidden="true"
                            />
                            {{ this.file.name }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
                <button
                  v-show="this.file"
                  @click="submitFile('Please insert file')"
                  type="button"
                  :class="
                    `uppercase rounded group body__header--button sm:text-sm lg:text-sm xl:text-sm md:text-sm text-white flex justify-center items-center text-sm font-medium shadow p-2 w-full mr-0`
                  "
                >
                  {{ $t("upload") }}
                </button>
                <div
                  class="px-5 py-5 bg-gray-50 space-y-6 sm:flex sm:space-y-0 sm:space-x-10 sm:px-8"
                >
                  <div class="flow-root">
                    <div
                      class="-m-3 p-3 flex items-center rounded-md text-base font-medium text-gray-900 hover:bg-gray-100 transition ease-in-out duration-150"
                    >
                      <InformationCircleIcon
                        class="flex-shrink-0 h-6 w-6 text-gray-400"
                        aria-hidden="true"
                      />
                      <span class="ml-3">{{ $t("importContactMsg") }} </span>
                    </div>
                  </div>
                </div>
              </div>
            </PopoverPanel>
          </transition>
        </Popover>
        <button
          :class="[
            `rounded body__header--button sm:text-sm lg:text-sm xl:text-sm md:text-sm text-white inline-flex items-center text-sm font-medium shadow p-auto px-1 mr-0`,
          ]"
          @click.prevent="exportDestinations"
        >
          <span class="uppercase">{{ $t("export") }}</span>
        </button>
      </div>
      <!-- <div class="">
        <div class="inline-flex ml-0 pb-1 pt-1 item-center">
          <input
            class="rounded text-center text-white pt-3 ml-0 w-auto items-center px-0 py-0 border border-transparent text-xs font-medium focus:outline-none "
            type="file"
            id="file"
            ref="file"
            v-on:change="handleFileUpload()"
          />
          <button
            @click="submitFile('Please insert file')"
            type="button"
            :class="
              `rounded text-center ml-0 items-center px-3 py-3 border border-transparent text-sm font-medium shadow-sm focus:outline-none body__header--button text-white`
            "
          >
            Upload file
          </button>
        </div>
      </div> -->
    </div>
  </div>
  <ask-confirmation-dialog
    ref="askConfirmationDialog"
  ></ask-confirmation-dialog>
  <ConfirmationDialog
    v-if="this.showMessage"
    :messageToShow="this.messageToShow"
    :subMessageToShow="this.subMessageToShow"
    :goBack="this.goBack"
  />
  <loading
    v-model:active="isLoading"
    :can-cancel="false"
    :on-cancel="onCancel"
    :is-full-page="fullPage"
  />
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import { ChevronDownIcon } from "@heroicons/vue/solid";
import {
  InformationCircleIcon,
  CloudUploadIcon,
  ExclamationCircleIcon,
} from "@heroicons/vue/outline";
import ConfirmationDialog from "../components/ConfirmationDialog.vue";
import AskConfirmationDialog from "../components/AskConfirmationDialog.vue";
import { Popover, PopoverButton, PopoverPanel } from "@headlessui/vue";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  name: "CTIDetailHeader",
  props: ["account", "id"],
  components: {
    ChevronDownIcon,
    AskConfirmationDialog,
    ConfirmationDialog,
    Loading,
    Popover,
    PopoverButton,
    PopoverPanel,
    InformationCircleIcon,
    CloudUploadIcon,
    ExclamationCircleIcon,
  },
  data() {
    return {
      isLoading: false,
      file: "",
      closeForm: true,
      showMessage: false,
      messageToShow: "",
      confirmationMessage: "",
      subMessageToShow: "",
      goBack: false,
      errorFormMandateUpload: false,
    };
  },
  methods: {
    dropHandler(ev) {
      console.log("File(s) dropped");

      // Prevent default behavior (Prevent file from being opened)
      ev.preventDefault();

      if (ev.dataTransfer.items) {
        // Use DataTransferItemList interface to access the file(s)
        for (var i = 0; i < ev.dataTransfer.items.length; i++) {
          // If dropped items aren't files, reject them
          const type = ev.dataTransfer.items[i].type;

          if (
            type === "multipart/form-data" ||
            type ===
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          ) {
            var file = ev.dataTransfer.items[i].getAsFile();
            this.file = file;
            this.errorFormMandateUpload = false;
          } else {
            this.errorFormMandateUpload = true;
          }
        }
      } else {
        // Use DataTransfer interface to access the file(s)
        for (var i = 0; i < ev.dataTransfer.files.length; i++) {
          console.log(
            "... file[" + i + "].name = " + ev.dataTransfer.files[i].name
          );
        }
      }
    },
    dragOverHandler(ev) {
      // Prevent default behavior (Prevent file from being opened)
      ev.preventDefault();
    },
    async exportDestinations() {
      this.isLoading = true;
      const res = await axios
        .get(
          `${this.$cookie.getCookie(
            "API"
          )}/api/v1/ctiDestinations/export?customerAccount=${
            this.account
          }&cti_id=${this.id}`,
          {
            headers: {
              "Content-Type": "application/json",

              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
            responseType: "blob",
          }
        )
        .catch((err) => {
          this.isLoading = false;
          console.error(err);
        });
      const file = new Blob([await res.data], {
        type:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const fileURL = URL.createObjectURL(file);
      this.isLoading = false;
      window.open(fileURL);
    },
    async deleteAllDestinations(
      id,
      title,
      confirmationMessage,
      confirmButton,
      goBack,
      successMsg
    ) {
      const r = await this.$refs.askConfirmationDialog.show({
        title: title,
        message: confirmationMessage,
        okButton: confirmButton,
        cancelButton: goBack,
        action: "delete",
      });
      if (r) {
        axios
          .delete(
            `${this.$cookie.getCookie(
              "API"
            )}/api/v1/ctiDestinations/all/${id}?customerAccount=${
              this.account
            }`,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            }
          )
          .then((res) => {
            if (res) {
              this.sendMessage(successMsg);
            }
          })
          .catch(function(error) {
            this.errorHandling(error);
          });
      }
    },
    sendMessage(msg, goBack) {
      this.showMessage = true;
      this.messageToShow = msg;
      this.goBack = goBack;
    },
    cancelForm() {
      this.closeForm = !this.closeForm;
      this.$forceUpdate();
    },
    handleFileUpload() {
      let type = this.$refs.file.files[0].type;
      if (
        type === "multipart/form-data" ||
        type ===
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      ) {
        this.file = this.$refs.file.files[0];
        this.errorFormMandateUpload = false;
      } else {
        this.errorFormMandateUpload = true;
      }
    },
    submitFile(noFileMsg) {
      /* Initialize the form data */
      let formData = new FormData();
      formData.append("file", this.file);
      /* Add the form data we need to submit */
      if (this.file) {
        this.isLoading = true;
        axios
          .post(
            `${this.$cookie.getCookie(
              "API"
            )}/api/v1/ctiDestinations/upload?customerAccount=${
              this.account
            }&cti_id=${this.id}`,
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                "content-type":
                  "multipart/form-data; boundary=---011000010111000001101001",
              },
            }
          )
          .then((res) => {
            this.isLoading = false;
            try {
              this.$emit("previewData", res.data);
            } catch (error) {
              this.errorHandling(error);
            }
            // this.preview = true;
            // this.previewData = res.data;
          })
          .catch((res) => {
            this.isLoading = false;
          });
      } else {
        this.sendMessage(noFileMsg, false);
      }

      /* Make the request to the POST /single-file URL  */
    },
  },
};
</script>

<style></style>
