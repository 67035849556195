<template>
 <div class="bg-white shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
  <div class="max-w-7xl mx-auto 2xl:px-0 px-4">
   <form @submit.prevent="postCTI()" method="POST">
    <div class="space-y-10">
     <div class="pt-5 border-b border-gray-200 pb-12">
      <div class="mt-5 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
       <div class="sm:col-span-3">
        <label for="cti-origin" class="block text-sm font-medium leading-6 text-gray-900">
         {{ $t("origin") }}
        </label>
        <div class="mt-2">
         <input
          v-model="this.form.origin"
          required
          type="text"
          name="cti-origin"
          id="cti-origin"
          class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
         />
        </div>
       </div>
       <div class="sm:col-span-6">
        <label for="cti-destination" class="block text-sm font-medium leading-6 text-gray-900">
         {{ $t("destination") }}
         <span class="text-xs text-gray-600 italic"
          >exemple: https://my-crm.com/customers?number=%CallerNumber%</span
         >
        </label>
        <div class="mt-2">
         <input
          required
          v-model="this.form.destination"
          type="text"
          name="cti-destination"
          id="cti-destination"
          class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
         />
        </div>
       </div>
      </div>
     </div>
    </div>

    <div class="mt-6 pb-12 flex items-center justify-end gap-x-6">
     <button
      type="button"
      @click="this.$emit('closeForm')"
      class="rounded-md bg-white px-3 py-2 border border-gray-300 shadow-sm text-sm font-semibold text-gray-900 hover:bg-gray-50"
     >
      {{ $t("ticket.cancel") }}
     </button>
     <button
      type="submit"
      class="rounded-md body__table--header px-3 py-2 text-sm font-semibold text-white shadow-sm"
     >
      {{ $t("ticket.send") }}
     </button>
    </div>
   </form>
  </div>
 </div>
</template>

<script>
import axios from "axios";

const account = localStorage.getItem("account");

export default {
 props: ["id"],
 components: {},
 data() {
  return {
   account,
   form: {
    origin: "",
    destination: "",
   },
  };
 },
 methods: {
  async postCTI() {
   if (this.form.origin !== "" || this.form.destination !== "") {
    const options = {
     method: "POST",
     url: `${this.$cookie.getCookie("API")}/api/v1/ctiDestinations`,
     params: {
      customerAccount: `${this.account}`,
      cti_id: `${this.id}`,
      origin: `${this.form.origin}`,
      destination: `${this.form.destination}`,
     },
     headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
     },
    };

    axios
     .request(options)
     .then((res) => {
      let msg = this.$t("elementCreatedSuccessfully", { element: this.$t("destination") });
      this.form.origin = "";
      this.form.destination = "";
      this.$emit("closeForm");

      this.$emit("activeBanner");
      this.$emit("success", true);
      this.$emit("successMsg", msg);
     })
     .catch((error) => {
      this.errorHandling(error);
      this.$emit("activeBanner");
      this.$emit("success", false);
      this.$emit("successMsg", this.errorDataResponse);
     });
   } else {
    window.alert("Please fill form.");
   }
  },
  sendMessage(msg, subMsg) {
   this.showMessage = true;
   this.messageToShow = msg;
   this.subMessageToShow = subMsg;
  },
 },
};
</script>

<style></style>
